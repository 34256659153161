<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />
    <div class="apipe-cloud-course-container-madeSolution">
      <div class="pc-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
            优制云仓储管理WMS系统
          </h1>
          <p class="tit">
            优制云WMS，针对仓储管理痛点，如流程繁琐、库存不清、效率低下，我们提供全链条仓配一体化<br />解决方案。集成条码、RFID技术，实现物料精准收货、质检、入库、上架。支持出库订单自动拣货<br />、定位分配，库存调拨与即时盘点，确保全局库存可视。亮灯拣货提升作业效率，流程与实物双重<br />追踪，构建精益生产物料管控体系。一体化集成，轻松对接现有系统，助力企业仓储管理升级。
          </p>
        </div>
        <MessageInfo />
      </div>

      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <div class="title">优制云仓储管理WMS系统</div>
          <div class="titwrap">
            <p class="tit">
              集成条码、RFID技术，实现物料精准收货、质检、入库、上架，助力企业仓储管理升级。
            </p>
          </div>
        </div>
      </div>

      <!-- 行业痛点 -->
      <div class="pc-industry d-none d-lg-block">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye1.png" alt="" />
                <h6>库存管理混乱</h6>
                <div class="des">
                  <div>库存数据不准确，导致过度库存</div>
                  <div>或库存不足</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye2.png" alt="" />
                <h6>货物流向不明</h6>
                <div class="des">
                  <div>货物追踪困难，无法及时了解库</div>
                  <div>存状态</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye3.png" alt="" />
                <h6>空间利用率低</h6>
                <div class="des">
                  <div>仓储空间分配不合理，浪费空间</div>
                  <!-- <div>效率难以提升</div> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye4.png" alt="" />
                <h6>出入库流程繁琐</h6>
                <div class="des">
                  <div>手工记录出入库信息，流程复</div>
                  <div>杂，容易出错</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <div class="mobile-industry d-block d-lg-none">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye1.png" alt="" />
                <h6>库存管理混乱</h6>
                <!-- <div class="des">
                  <div>库存数据不准确，导致过度库存</div>
                  <div>或库存不足</div>
                </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye2.png" alt="" />
                <h6>货物流向不明</h6>
                <!-- <div class="des">
                  <div>货物追踪困难，无法及时了解库</div>
                  <div>存状态</div>
                </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye3.png" alt="" />
                <h6>空间利用率低</h6>
                <!-- <div class="des">
                  <div>仓储空间分配不合理，浪费空间</div>
                </div> -->
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/hangye4.png" alt="" />
                <h6>出入库流程繁琐</h6>
                <!-- <div class="des">
                  <div>手工记录出入库信息，流程复</div>
                  <div>杂，容易出错</div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 解决方案 -->
      <div class="container text-center">
        <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
          解决方案
        </div>
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          解决方案
        </div>
        <img
          style="width: 100%"
          src="@/assets/newSite/wms/solution.png"
          alt=""
        />
      </div>

      <!-- 产品能力 -->
      <div class="pc-pro-power d-none d-lg-block">
        <div class="pro-tit" style="font-size: 36px">产品能力</div>
        <div>
          <div class="tabs container text-center">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    入库通知单、收货任务、质检任务、上架任务
                  </div>
                  <div class="mcot">
                    <div>
                      负责处理货物从接收到正式入库的全过程。它支持多种入库方式（如采购入库、退货入库、生产入库等），通过扫描条码快速识别和标注货物信息，减少人工错误。同时，该模块还能实时更新库存数据，优化库存布局，提高入库效率与准确性。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">出库单、拣货任务、OQC检验</div>
                  <div class="mcot">
                    <div>
                      出库管理模块负责处理货物出库请求，包括出库单订单处理、拣货、出库等环节。系统根据出库单自动生成拣货任务，确保货物按需求高效出库。同时，提供出库OQC功能，确保货物准确无误地离开仓库
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">调拨管理、盘点管理、移库单</div>
                  <div class="mcot">
                    <div>
                      库内管理模块负责仓库内部的日常运营管理。它涵盖库存盘点、库位调整、货物移库、调拨等功能。通过实时监控库存状态与库位变动，实现库存动态平衡与优化
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">条码补印、条码拆分、条码合并</div>
                  <div class="mcot">
                    <div>
                      条码管理模块它负责条码的补印、拆分与合并，确保每一件货物都有唯一的身份标识。通过条码，实现货物从入库到出库的全链条追踪与管理，提高数据录入的准确性与效率
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    库存报表、库龄报表、条码追溯、入库明细、出库明细等
                  </div>
                  <div class="mcot">
                    <div>
                      报表管理模块提供丰富的报表与数据分析功能，帮助管理层了解仓库运营状况。包括库存报表、出入库明细、库龄分析等多种报表类型。为决策制定提供有力支持
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-pro-power d-block d-lg-none">
        <h4 class="pro-tit">产品能力</h4>
        <div class="">
          <div class="tabs">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    入库通知单、收货任务、质检任务、上架任务
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      负责处理货物从接收到正式入库的全过程。它支持多种入库方式（如采购入库、退货入库、生产入库等），通过扫描条码快速识别和标注货物信息，减少人工错误。同时，该模块还能实时更新库存数据，优化库存布局，提高入库效率与准确性。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">出库单、拣货任务、OQC检验</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      出库管理模块负责处理货物出库请求，包括出库单订单处理、拣货、出库等环节。系统根据出库单自动生成拣货任务，确保货物按需求高效出库。同时，提供出库OQC功能，确保货物准确无误地离开仓库
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">调拨管理、盘点管理、移库单</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      库内管理模块负责仓库内部的日常运营管理。它涵盖库存盘点、库位调整、货物移库、调拨等功能。通过实时监控库存状态与库位变动，实现库存动态平衡与优化
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">条码补印、条码拆分、条码合并</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      条码管理模块它负责条码的补印、拆分与合并，确保每一件货物都有唯一的身份标识。通过条码，实现货物从入库到出库的全链条追踪与管理，提高数据录入的准确性与效率
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    库存报表、库龄报表、条码追溯、入库明细、出库明细等
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      报表管理模块提供丰富的报表与数据分析功能，帮助管理层了解仓库运营状况。包括库存报表、出入库明细、库龄分析等多种报表类型。为决策制定提供有力支持
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/wms/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="pc-scene d-none d-lg-block">
        <div class="pro-tit d-none d-lg-block">产品优势</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="mobile-scene d-block d-lg-none">
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          产品优势
        </div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/wms/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 成功案列 -->
      <section
        class="pc-product d-none d-lg-block"
        style="margin: 80px 0 50px 0"
      >
        <div class="container text-center">
          <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
            成功案例
          </h1>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <span>{{ item.customer }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div v-if="caseA.title" class="content" style="display: flex; padding: 20px 0">
              <div class="wrap" style="padding-right: 10px;">
                <h6 style="font-size: 35px">
                  {{ caseA.title }}
                </h6>
                <p class="p-content">
                  {{ caseA.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseA.id}&tit=${caseA.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="caseA.url"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div v-if="caseB.title" class="content" style="display: flex; padding: 20px 0">
              <div class="wrap" style="flex: 60%;padding-right: 10px;">
                <h6 style="font-size: 35px">{{ caseB.title }}</h6>
                <p class="p-content">
                  {{ caseB.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseB.id}&tit=${caseB.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="caseB.url"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div v-if="caseC.title" class="content" style="display: flex; padding: 20px 0">
              <div class="wrap" style="flex: 60%;padding-right: 10px;">
                <h6 style="font-size: 35px">{{ caseC.title }}</h6>
                <p class="p-content">
                  {{ caseC.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseC.id}&tit=${caseC.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="caseC.url"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </section>

      <section
        class="mobile-product d-block d-lg-none"
        style="margin-top: 40px"
      >
        <div class="container text-center">
          <h3 class="pro-tit">成功案例</h3>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <span>{{ item.customer }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div v-if="caseA.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  {{ caseA.title }}
                </h6>
                <p class="p-content">
                  {{ caseA.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseA.url" />
              </div>
              <div>
                <el-button
                  type="primary"
                  round
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseA.id}&tit=${caseA.title}`
                      );
                    }
                  "
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div v-if="caseB.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  {{ caseB.title }}
                </h6>
                <p class="p-content">
                  {{ caseB.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseB.url" />
              </div>
              <div>
                <el-button
                  type="primary"
                  round
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseB.id}&tit=${caseB.title}`
                      );
                    }
                  "
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div v-if="caseC.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 0.5rem">
                  {{ caseC.title }}
                </h6>
                <p class="p-content">
                  {{ caseC.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseC.url" />
              </div>
              <div>
                <el-button
                  type="primary"
                  round
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseC.id}&tit=${caseC.title}`
                      );
                    }
                  "
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </section>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter @handleClickChild="handleClickChild" />
    <MobileFooter ref="mofooter" class="d-block d-lg-none" />
  </div>
</template>
          
  <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);
import Qs from "qs";
import { caselist } from "@/api/fetch";

export default {
  name: "hardware",
  data() {
    return {
      tabs: ["优管网", "数研时空", "润玉空间"],
      activeName: "1",
      idx: 0,
      tbx: 0,
      ids: -1,
      tabListNew: [
        {
          img1: require("@/assets/newSite/wms/p1.png"),
          tit: "入库管理",
        },
        {
          img1: require("@/assets/newSite/wms/p2.png"),
          tit: "出库管理",
        },
        {
          img1: require("@/assets/newSite/wms/p3.png"),
          tit: "库内管理",
        },
        {
          img1: require("@/assets/newSite/wms/p4.png"),
          tit: "条码管理",
        },
        {
          img1: require("@/assets/newSite/wms/p5.png"),
          tit: "报表模块",
        },
      ],

      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseImgUrl: "https://admin.veiban.com",
      caseList: [],
      caseA: {},
      caseB: {},
      caseC: {},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.getCase();
  },
  metaInfo() {
    return {
      title: "优制云仓储管理 - 高效智能的仓储管理解决方案",
      meta: [
        {
          name: "keywords",
          content:
            "优制云工业互联网平台仓储管理WMS系统为中小微企业提供先进的库存管理、物料跟踪、条码管理、收货管理等功能，实现仓储操作的数字化和精细化管理，帮助企业提升库存周转率和仓库利用率。",
        },
        {
          name: "description",
          content:
            "仓储管理系统WMS,库存管理,物料跟踪,收货管理,智能仓储,条码管理,工业互联网,SaaS系统,优制云,伟班平台",
        },
      ],
    };
  },

  methods: {
    handleClickChild() {
      this.$refs.mofooter.handleDeal(2);
    },
    //成功案例
    getCase() {
      const prams = {
        pageSize: 10,
        pageNo: 1,
        newsType: 0,
        productCode: "WMS",
      };
      caselist(prams).then((res) => {
        let list = res.data.list;
          if (list.length) {
            this.caseA = list[0] || {};
            this.caseB = list[1] || {};
            this.caseC = list[2] || {};
          }
          //取三条数据 边界判断
          if (list.length > 3) {
            this.caseList = list.slice(0, 3);
          }
          if (list.length == 0) {
            this.caseList = [
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
            ];
          }
          if (list.length == 1) {
            let lis = [
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
            ];
            this.caseList = list.concat(lis);
          }
          if (list.length == 2) {
            let lis = [{ customer: "暂未配置客户" }];
            this.caseList = list.concat(lis);
          }
          if (list.length == 3) {
            this.caseList = list;
          }
      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({ path, id: "123456" });
      window.open(routeData.href, "_blank");
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    handleTab(index) {
      this.tbx = index;
      // this.idx = index;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
          
  <style lang="less" scoped>
.apipe-cloud-course-container-madeSolution {
  .pc-banner {
    height: 600px;
    background-image: url("../../assets/newSite/wms/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 18px;
        margin: 4px 0;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 100px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 300px;
    width: 100%;
    background-image: url("../../assets/newSite/wms/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .titwrap {
        padding: 30px 20px;
        .tit {
          font-size: 13px;
          margin: 5px 0;
        }
      }
      .title {
        font-size: 35px;
        text-align: left;
        color: white;
        font-family: Source Han Sans CN;
        font-weight: 200;
        margin-bottom: 30px;
      }
      .tit {
        font-size: 14px;
        margin-top: 15px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        flex: 25%;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .tit {
          padding: 20px 0 16px 0;
          font-size: 18px;
          font-weight: 700;
        }
        &:hover {
          cursor: pointer;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 20px 100px;
        .mtit {
          text-align: left;
          font-size: 24px;
          font-weight: 500;
        }
        .mcot {
          text-align: left;
          margin: 20px 0 30px 0;
          color: #333333;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 120px;
            background: #1484ff;
            &:hover {
              background: #66b1ff;
            }
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .mobile-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 20px;
      color: #333;
      font-weight: 700;
    }
    .tabs {
      display: flex;
      width: 375px;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 auto;
      .items {
        width: 33%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        .tit {
          padding: 20px 0 16px 0;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px 0;
      .row {
        min-height: 300px;
        padding: 10px;
        h4 {
          font-family: Source Han Sans CN;
          font-weight: 700;
          color: #333333;
          font-size: 16px;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .pc-scene {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 0 !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .mobile-scene {
    margin: 0 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        padding: 5px !important;
      }
    }
    img {
      width: 100%;
      // height: 320px;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .pc-industry {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 40px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        width: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .mobile-industry {
    margin: 40px 0;
    .pro-tit {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #333;
    }
    .col {
      .p-3 {
        background: #f6faff;
        margin: 10px;
      }
    }
    img {
      width: 100px;
      // height: 100px;
    }
    h6 {
      font-family: Source Han Sans CN;
      font-weight: 200;
      color: #333333;
      font-size: 15px;
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .pc-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-wrap {
      background: #f6faff;
      text-align: left;
      .container {
        display: flex;

        .cot {
          background: url("../../assets/newSite/home/pc.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 702px;
          width: 100%;
        }
        img {
          // height: 16rem;
        }
        .content {
          margin: 40px 0;
          width: 100%;
          .wrap {
            flex: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-family: Source Han Sans CN;
              font-weight: 200;
              color: #333333;
              width: 540px;
              margin-bottom: 20px;
              margin-top: 12px;
              font-size: 18px;
            }
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
          }
        }
        .noData {
          padding: 60px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
  .mobile-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      padding: 10px 0 0 0;
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
        font-family: Source Han Sans CN;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .pro-tit {
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .pro-wrap {
      text-align: left;
      background: #f6faff;
      .container {
        display: flex;
        .content {
          padding: 2rem 1.5rem 1.5rem 1.5rem;

          width: 100%;
          img {
            width: 100%;
            // height: 20rem;
          }
          .p-content {
            color: #666;
            font-size: 0.8rem;
            font-family: "思源黑体";
            line-height: 1.6rem;
          }
          .btn {
            width: 120px;
            background: #1484ff;
            color: white;
          }
        }
        .noData {
          padding: 60px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}
</style>
          